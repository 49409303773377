<template>
  <div class="app-container" style="height: 100%;">
    <el-row style="height: 100%;" v-loading="treeLoading">
      <el-col :span="8" style="height: 100%;border-right: 1px solid #ccc;padding-right: 6px;">
        <el-row style="text-align:left;padding:10px 0px;">
          <div :span="24" style="border-left: 5px solid #0073E9;padding-left: 10px;height: 20px;">药品类别目录树</div>
          <el-row style="padding-top: 10px;">
            <el-col :span="16" >
              <el-input v-model="treeFilterText" size="small" style="min-width: 100px;" maxlength="50"
                        clearable  placeholder="输入关键字进行过滤">
                <el-button slot="append" icon="el-icon-search" ></el-button>
              </el-input>
            </el-col>
            <el-col :span="8" style="padding-left: 10px;" >
              <el-button icon="el-icon-refresh" type="primary" size="small" title="刷新" @click="treeList"></el-button>
              <el-button icon="el-icon-circle-plus-outline" type="info" size="small" title="新增" @click="addNode()"></el-button>
            </el-col>
          </el-row>

        </el-row>
        <el-tree
          v-loading="treeLoading"
          style="height:calc(100% - 90px);overflow: auto"
          ref="cateGoryTree"
          v-if="showDirTree"
          :data="treeData"
          :props="defaultProps"
          :current-node-key="1"
          :highlight-current="true"
          node-key="id"
          :filter-node-method="filterNode"
          :expand-on-click-node="false"
          @node-click="handleNodeClick"
        >
          <div class="custom-tree-node"
               slot-scope="{ node, data }"
               @mouseenter="treeNodeMouseEnter(data)"
               @mouseleave="treeNodeMouseLeave(data)"
          >
            <el-tooltip class="item" effect="light"  placement="top" :open-delay=600>
              <div slot="content" style="font-size: 14px;max-width: 400px;">
                <span style="margin-bottom: 2px;"> {{ data.code+'-'+data.name+'-'+data.id }}</span>
                <br/>{{data.comment}}</div>
              <span class="tree-node-name">
              <svg-icon :icon-class="data.icon" v-if="data.icon"/>
              <span v-show="data.categoryType==15">{{data.code+'-'}}</span>
                {{ data.name+'-'+data.id }}
            </span>
            </el-tooltip>
            <div style="min-width: 130px;display: inline-block">
              <el-link v-if="data.show && node.expanded==false" size="mini" @click.stop
                       style="position: relative;margin-left: 8px;" type="primary" :underline="false"
                       @click="() => expandNode(node, data)" title="展开全部"
                       icon="fa fa-chevron-circle-right fa-lg"></el-link>
              <el-link v-if="data.show && node.expanded==true" size="mini" @click.stop
                       style="position: relative;margin-left: 8px;" type="primary" :underline="false"
                       @click="() => foldNode(node, data)" title="折叠全部"
                       icon="fa fa-chevron-circle-down fa-lg"></el-link>

              <el-link v-if="data.show && data.allowEdit==1" size="mini" @click.stop
                       style="margin-left: 8px;position: relative;" type="primary" :underline="false"
                       @click="() => editNode(node, data)" title="编辑"
                       icon="fa fa-edit fa-lg"></el-link>

              <el-link v-if="data.show && data.allowEdit==1" size="mini" @click.stop
                       style="margin-left: 8px;position: relative;" type="primary" :underline="false"
                       @click="() => addNode(data)" title="新增子级"
                       icon="fa fa-plus-square fa-lg"></el-link>

              <el-link v-if="data.show && data.allowEdit==1" size="mini" @click.stop
                       style="margin-left: 8px;position: relative;" type="primary" :underline="false"
                       @click="() => remove(node, data)" title="删除"
                       icon="fa fa-trash-o fa-lg"></el-link>
            </div>

          </div>
        </el-tree>
      </el-col>
      <el-col :span="16" style="height: 100%;padding-left: 6px;">
        <el-row style="padding:10px 0px;"  id="relevanceMsgRow" >
          <el-col :span="4">
            <el-col :span="24" style="border-left: 5px solid #0073E9;padding-left: 10px;">关联列表</el-col>
          </el-col>
          <el-col :span="10" style="color: #409eff">
            <p>
              <span style="font-weight: bolder;margin-right: 20px;"> {{ nowClickNodeData.name }}</span>
              <span style="font-weight: 300;">{{ nowClickNodeData.comment }}</span>
            </p>
            <p v-for="item in nowClickNodeData.extPropertyList">
              <span style="font-weight: 300;padding-right: 10px;">{{ item.propertyName+':' }}</span>
              <span style="font-weight: 300;">{{ item.propertyVal}}</span>
            </p>

          </el-col>
          <el-col :span="10" style="text-align:right;">
            <el-button icon="el-icon-circle-plus-outline"  :disabled="treeLoading?true:false"
                       size="mini" class="addClass" @click="addTableList">新增
            </el-button>
            <el-button icon="el-icon-s-operation"  :disabled="treeLoading?true:false"
                       size="mini" class="addClass"  @click="conditionEditorVisible=true">条件设置
            </el-button>
            <el-button icon="el-icon-refresh"  :disabled="treeLoading?true:false"
                       size="mini" class="addClass"  @click="refreshDirRefData">数据同步
            </el-button>
          </el-col>
        </el-row>

        <el-table
          v-if="true"
          :data="tableData"
          border
          style="width: 100%"
          v-loading="loading"
          :height="tableHeight"
          :header-cell-style="{'text-align':'center'}"
          :cell-style="{'text-align':'center'}"
          highlight-current-row
          ref="selectRelevanceDataTable"
          :default-sort="{prop: 'drugFlagCode', order: 'descending'}"
        >
          <el-table-column type="index"  fixed="left" width="50"></el-table-column>
          <el-table-column
            prop="drugFlagTypeStr"
            label="标识类型"
            width="130"
            sortable
          >
          </el-table-column>
          <el-table-column
            prop="drugFlagCode"
            label="标识ID"
            width="80"
            sortable
          >
          </el-table-column>
          <el-table-column
            prop="name"
            label="名称"
            min-width="200"
            sortable
          >
          </el-table-column>
          <el-table-column
            prop="desc"
            label="描述"
            min-width="200"
          >
            <template slot-scope="scope">
              <el-popover placement="top" title="描述" trigger="click" width="600">
                <div>
                  <p>
                    {{ scope.row.desc }}
                  </p>
                </div>
                <span slot="reference" class="setTwoLine" style="color: #2B60F8;font-weight: 700;" title="点击查看全部描述">
                  {{ scope.row.desc }}
                </span>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column
            prop="updateTime"
            label="更新时间"
            width="150"
            sortable
          >
          </el-table-column>
          <el-table-column
            label="来源"
            width="250"
          >
            <template slot-scope="scope">
              <p v-if="!scope.row.conditionId">
                手动添加
              </p>
              <div v-if="scope.row.conditionId" v-for="(condition,index) in scope.row.conditionList" class="condition-div">
                <span style="color: #4B56F2">{{ condition.fieldName }}</span>
                等于
                <el-tag v-for="t in condition.val" size="small"
                        style="margin-right: 4px;" >{{ t.name }}
                </el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            class-name="small-padding fixed-width"
            width="100"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                style="color: #FF3333;"
                @click="handleDelete(scope.row)"
              >删除
                <!--                style="color: #FF3333;"-->
              </el-button>
            </template>
          </el-table-column>
          <template slot="empty">
            <img src="@/assets/images/no-booking.svg" alt="">
            <p>暂无数据</p>
          </template>
        </el-table>

        <pagination
          v-show="total>0"
          :total="total"
          :page.sync="pageNum"
          :limit.sync="pageSize"
          layout="total"
          @pagination="getInfo()"
          :page-sizes="[20, 50, 100,500,1000]"
        />
      </el-col>
    </el-row>
    <el-dialog
      v-dialogDrag
      :title='form.editNode?"编辑分类":"新增分类"'
      :visible.sync="addTreeVisible"
      width="50%"
      :close-on-click-modal="false"
    >
      <el-form :model="form" ref="editCategoryDirForm" label-width="80px">

        <el-form-item
          label="上级分类"
          prop="pid"
          :rules="[
            { required: true, message: '上级分类不能为空'},
          ]"
        >
          <treeselect
            v-if="addTreeVisible"
            v-model="form.pid"
            :options="selectTreeData"
            :normalizer="normalizer"
            :show-count="true"
            placeholder="选择上级分类"
          />
        </el-form-item>

<!--        <el-form-item-->
<!--          label="上级分类"-->
<!--          prop="pName" v-show="!form.editPNo1de1"-->
<!--        >-->
<!--          <el-input v-model="form.pName" :disabled="true">-->
<!--            <el-button slot="append" title="点击编辑上级分类" icon="el-icon-edit-outline" @click="form.editP1Node=true"></el-button>-->
<!--          </el-input>-->
<!--        </el-form-item>-->

        <el-form-item
          label="类别编码"
          prop="code"
          :rules="[
            { required: true, message: '类别编码不能为空'},
             { validator: validateCategoryCode, trigger: 'blur'}
          ]"
        >
          <el-input v-model="form.code" maxlength="50" show-word-limit></el-input>
        </el-form-item>

        <el-form-item
          label="类别名称"
          prop="name"
          :rules="[
            { required: true, message: '类别名称不能为空'},
             { validator: validateCategoryName, trigger: 'blur'}
          ]"
        >
          <el-input v-model="form.name" maxlength="200" show-word-limit ></el-input>
        </el-form-item>

        <el-form-item
          label="备注"
          prop="comment"
        >
          <el-input v-model="form.comment" type="textarea" :rows="6" maxlength="1000" show-word-limit></el-input>
        </el-form-item>


      </el-form>
      <span slot="footer" class="dialog-footer" style="margin-right:20px;">
        <el-button @click="addTreeVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitEditTree('editCategoryDirForm')">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      top="5px"
      v-dialogDrag
      title="新增关联列表"
      :visible.sync="addTableVisible"
      width="80%"
      :before-close="addTableClose"
      :close-on-click-modal="false"
      id="addRelevanceDialog"
    >
      <el-breadcrumb separator-class="el-icon-arrow-right" style="padding: 6px;">
        <el-breadcrumb-item v-show="activeBreadcrumb>=1" >
          <div @click="breadcrumbItemClick(1)"><p class="breadcrumb-item-name">药物</p></div>
        </el-breadcrumb-item>
        <el-breadcrumb-item v-show="activeBreadcrumb>=2" >
          <div @click="breadcrumbItemClick(2)"><p class="breadcrumb-item-name">制剂</p></div>
        </el-breadcrumb-item>
        <el-breadcrumb-item v-show="activeBreadcrumb>=3">
          <div @click="breadcrumbItemClick(3)"><p class="breadcrumb-item-name">制剂规格</p></div>
        </el-breadcrumb-item>
        <el-breadcrumb-item v-show="activeBreadcrumb>=4">
          <div><p class="breadcrumb-item-name">药品</p></div>
        </el-breadcrumb-item>
        <el-breadcrumb-item v-show="activeBreadcrumb>=5">
          <div><p class="breadcrumb-item-name">包装药品</p></div>
        </el-breadcrumb-item>
      </el-breadcrumb>

      <el-row style="padding:10px 0px;">
        <el-col :span="18">
          <el-form :inline="true" :model="addRelevanceForm" class="demo-form-inline" ref="relevanceQueryForm">
            <el-form-item label="" prop="searchValue"
                          :rules="[{ required: false, message: '请输入查询关键字', trigger: 'blur'} ]"
            >
              <el-input v-model="addRelevanceForm.searchValue" placeholder="请输入关键字" clearable
                        style="width:350px;"
                        @keydown.enter.native="searchDataList(1)"
              ></el-input>
            </el-form-item>
            <el-form-item label="">
              <el-select v-model="addRelevanceForm.drugFlag"   style="width:120px;"
                         placeholder="请选择类型" @change="typeSelectChange">
                <el-option label="物质" value="1"></el-option>
                <el-option label="药物" value="3"></el-option>
                <el-option label="药物组" value="2"></el-option>
                <el-option label="制剂" value="4"></el-option>
                <el-option label="制剂规格" value="5"></el-option>
                <el-option label="药品" value="6"></el-option>
                <!--            <el-option label="包装药品" value="7"></el-option>
                            <el-option label="组合包装药品" value="8"></el-option>-->
              </el-select>
            </el-form-item>
            <el-form-item label="" v-if="addRelevanceForm.drugFlag==3">
              <el-select v-model="addRelevanceForm.drugClassify"  clearable style="width:150px;"
                         placeholder="请选择药物分类" @change="drugClassifySelectChange">
                <el-option
                  v-for="item in drugClassifyDict"
                  :key="item.conceptId"
                  :label="item.conceptVal"
                  :value="item.conceptId"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="searchDataList(1)">查询</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="6" style="color: #409eff">
          <el-link type="primary1"
                   style="font-weight: bolder;margin-right: 20px;line-height: 24px;"
                   @click="addRelevanceForm.searchValue= nowClickNodeData.name"> {{ nowClickNodeData.name }}</el-link>
          <span style="font-weight: 300;">{{ nowClickNodeData.comment }}</span>
        </el-col>
      </el-row>


      <el-table
        ref="relevanceTable"
        class="relevance-table"
        border
        :data="addRelevanceTableData"
        style="width: 100%;overflow: auto"
        height="400"
        v-loading="loading"
        :header-cell-style="{'text-align':'center'}"
        :cell-style="{'text-align':'center'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" :selectable="rowCanChecked" width="55"/>
        <el-table-column type="index" width="50"></el-table-column>
        <el-table-column
          v-for="data in tableOptions[addRelevanceForm.drugFlag]"
          :key="data.id"
          :prop="data.prop"
          :label="data.name"
          :fixed="false"
          :resizable="false"
          :sortable="false"
          :min-width="data.width?data.width:'80'"
          align="center"
        >
          <template slot-scope="scope">
            <div v-if="data.prop == 'substanceDesc'">
              <el-popover placement="top" title="物质描述" trigger="click" width="600">
                <div>
                  <p>
                    {{ scope.row.substanceDesc }}
                  </p>
                </div>
                <span slot="reference" class="setTwoLine" style="color: #2B60F8;font-weight: 700;" title="点击查看全部物质描述">
                  {{ scope.row.substanceDesc }}
                </span>
              </el-popover>
            </div>

            <div v-else-if="data.prop == 'includeDrugList'">
              <el-popover trigger="click" placement="top">
                <div>
                  <p style="text-align:center;"><b>{{ scope.row.name }}</b></p>
                  <p v-for="item1 in scope.row.dkbDrugGroupMappingList" :key="item1.id">
                    [<span>{{ item1.drugId }}</span>]-<span>{{ item1.drugName }}</span>；
                  </p>
                </div>
                <span slot="reference" class="name-wrapper setTwoLine">
                  <span v-for="item1 in scope.row.dkbDrugGroupMappingList" :key="item1.id">
                    <span :class="{delClass: item1.status === '1'}">
                      [<span>{{ item1.drugId }}</span>]-<span>{{ item1.drugName }}</span>；
                    </span>
                  </span>
                </span>
              </el-popover>
            </div>

            <div v-else-if="data.prop == 'drugName'" title="点击查看属于该药物的制剂">
              <el-link type="primary" @click="clickDrugRowSearch(scope.row,1)"> {{ scope.row.drugName }}</el-link>
            </div>
            <div v-else-if="data.prop == 'preparationAlias'" title="点击查看该制剂的全部规格">
              <el-link type="primary" @click="clickDrugRowSearch(scope.row,2)"> {{ scope.row.preparationAlias }}</el-link>
            </div>
            <div v-else-if="data.prop == 'preparationSpecDesc'" title="点击查看该制剂规格的全部药品">
              <el-link type="primary" @click="clickDrugRowSearch(scope.row,3)"> {{ scope.row.preparationSpecDesc }}</el-link>
            </div>
            <div v-else>
              <span>{{ scope.row[data.prop] }}</span>
            </div>
          </template>
        </el-table-column>

        <template slot="empty">
          <img src="@/assets/images/no-booking.svg" alt="">
          <p>暂无数据</p>
        </template>
      </el-table>

      <pagination
        v-show="addRelevanceTableDataTotal>0"
        :total="addRelevanceTableDataTotal"
        :page.sync="addRelevanceForm.pageNum"
        :limit.sync="addRelevanceForm.pageSize"
        layout="total, sizes, prev, pager, next"
        @pagination="searchDataList()"
        :page-sizes="[20, 50, 100]"
      />

      <span slot="footer" class="dialog-footer" style="margin-right:20px;">
        <el-button @click="cancleAddTable">取 消</el-button>
        <el-button type="primary" @click="submitAddTable">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      top="5px"
      v-dialogDrag
      title="规则列表"
      :visible.sync="conditionEditorVisible"
      width="80%"
    >
      <ConditionEditor :categoryDirId="drugCategoryId"
                       v-on:saveCondition = "saveCondition($event)"
                       v-if="conditionEditorVisible"></ConditionEditor>
    </el-dialog>
  </div>


</template>

<script>
import IconSelect from '@/components/IconSelect'
import Treeselect from '@riophae/vue-treeselect'
import ConditionEditor from './conditionEditor'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import {
  categoryDirInfo,editCategoryDir,
  dirSearch,
  delCategoryDir,
  info,
  filter,
  dirMap,
  dirMapDel,
  checkFields,
  getTreeByCategoryType,
  refreshDirRefData
} from '@/api/dkm/drugCategoryTreeList'
import { isEmpty } from '@/utils/common'
import { list as substanceList } from '@/api/dkm/substance'
import { list as drugGroupList } from '@/api/dkm/drugGroup'
import { list as drugList } from '@/api/dkm/drug'
import { preparationList } from '@/api/dkm/preparationSpecification'
import { querySpecList as preparationSpecList } from '@/api/dkm/preparationSpecification'
import { list as drugProdList, pdkDrugProdList } from '@/api/dkm/drugProd'
import { comboDrugList } from '@/api/dkm/pkgDrugProd'
import conceptConst from '@/const/conceptConst'
import { getConcept } from '@/utils/concept'
import { deepClone } from '@/utils'
export default {
  name: 'DrugCategoryTreeList',
  components: { IconSelect, Treeselect,ConditionEditor },
  watch: {
    /*监听搜索值变化过滤树节点*/
    treeFilterText(val) {
      if(!isEmpty(val)){
        this.$refs.cateGoryTree.filter(val);
      }else{
        //搜索条件为空，重新加载树
        this.showDirTree = false
        this.$nextTick(() => {
          this.showDirTree = true
        })
        //折叠全部
        //let rootNode=this.$refs.cateGoryTree.store.root
        //this.foldNode(rootNode)
      }

    }
  },
  data() {
    return {
      conceptConst,
      treeLoading:false,
      // 遮罩层
      loading: false,
      showDirTree: true,
      treeFilterText:undefined,
      treeData: [],
      treeIdToNameDict:{},
      /*编辑目录时候，选择上级分类的树结构数据*/
      selectTreeData: [],
      selectTreeShow: true,
      defaultProps: {
        label: 'name',
        isLeaf: 'hasChildNode',
      },
      /*当前点击的树node数据*/
      nowClickNodeData:{},
      form: {
        id: undefined,
        name: '',
        code: '',
        icon: '',
        pid: undefined,
        comment:'',
        //编辑或新增
        editNode:true,
      },
      addTreeVisible: false, // 新增目录弹出层
      addTableVisible: false, // 关联列表弹出层
      drugCategoryId: '', // 类别目录ID
      // 总条数
      total: 0,
      pageNum: 1,
      pageSize: 99999,
      orderByColumn: undefined,
      isAsc: undefined,
      tableData: [],
      // 表格高度
      tableHeight: window.innerHeight - 200,
      tableChoiceData: [], // 选择数据,
      /*新增关联数据表格*/
      addRelevanceTableData: [],
      addRelevanceTableDataTotal: 0,
      addRelevanceForm: {
        searchValue: '',
        drugFlag: '1',
        pageNum: 1,
        pageSize: 20,
        drugId:undefined
      },
      tableOptions: {
        '1': [
          {
            prop: 'id',
            name: '物质ID',
            width: '100'
          },
          {
            prop: 'substanceName',
            name: '物质名称',
            width: '200'
          },
          {
            prop: 'substanceDesc',
            name: '物质描述',
            width: '200'
          }
        ],
        '2': [
          {
            prop: 'id',
            name: '药物组ID',
            width: '50'
          },
          {
            prop: 'name',
            name: '药物组名称',
            width: '200'
          },
          {
            prop: 'includeDrugList',
            name: '包含药物列表',
            width: '250'
          }
        ],
        '3': [
          {
            prop: 'id',
            name: '药物ID',
            width: '50'
          },
          {
            prop: 'drugName',
            name: '药物名称',
            width: '200'
          },
          {
            prop: 'classifyDesc',
            name: '中西药标志',
            width: '80'
          },
          {
            prop: 'ingredient',
            name: '药物成分',
            width: '200'
          }
        ],
        '4': [
          {
            prop: 'id',
            name: '制剂ID',
            width: '50'
          },
          {
            prop: 'preparationAlias',
            name: '制剂名称',
            width: '200'
          },
          {
            prop: 'typeName',
            name: '制剂剂型',
            width: '80'
          },
          {
            prop: 'routeName',
            name: '用药途径',
            width: '80'
          },
          {
            prop: 'siteName',
            name: '给药部位',
            width: '80'
          },
          {
            prop: 'drugTypeSpecName',
            name: '中药类型及规格',
            width: '80'
          },
          {
            prop: 'drugProcMethodName',
            name: '中药炮制方法',
            width: '80'
          }
        ],
        '5': [
          {
            prop: 'id',
            name: '制剂规格ID',
            width: '100'
          },
          {
            prop: 'preparationName',
            name: '制剂名称',
            width: '200'
          },
          {
            prop: 'preparationForm',
            name: '制剂剂型',
            width: '80'
          },
          {
            prop: 'preparationSpecDesc',
            name: '制剂规格描述',
            width: '150'
          }
        ],
        '6': [
          {
            prop: 'id',
            name: '药品ID',
            width: '100'
          },
          {
            prop: 'genericName',
            name: '通用名',
            width: '200'
          },
          {
            prop: 'tradeName',
            name: '商品名',
            width: '200'
          },
          {
            prop: 'specDesc',
            name: '药品规格',
            width: '200'
          },
          {
            prop: 'manufacturerName',
            name: '厂商',
            width: '150'
          },
          {
            prop: 'standardCode',
            name: '本位码',
            width: '150'
          },
          {
            prop: 'approvalNum',
            name: '批准文号',
            width: '150'
          },
          {
            prop: 'domesticOrImportDesc',
            name: '国产/及进口标志',
            width: '150'
          }
        ],
        '7': [
          {
            prop: 'id',
            name: '包装药品ID',
            width: '100'
          },
          {
            prop: 'pkgDrugProdName',
            name: '包装药品名称',
            width: '200'
          },
          {
            prop: 'packSpecDesc',
            name: '包装规格描述',
            width: '200'
          }
        ],
        '8': [
          {
            prop: 'id',
            name: '组合包装药品ID',
            width: '100'
          },
          {
            prop: 'comboDrugProdName',
            name: '组合包装药品名称',
            width: '200'
          },
          {
            prop: 'packSpecDesc',
            name: '组合包装规格描述',
            width: '200'
          },
          {
            prop: 'groupPackLevelDesc',
            name: '包装合并级别',
            width: '100'
          }
        ]
      },
      /*面包屑导航-激活的*/
      activeBreadcrumb:0,
      breadcrumbDesc:{
        drugName:undefined,
        preparationAlias:undefined,
        preparationSpecDesc:undefined,
        genericName:undefined,
        packSpecDesc:undefined
      },
      breadcrumbSearchParams:{
        drugId:undefined,
        ppId:undefined,
        specId:undefined,
      },
      conditionEditorVisible:false,
      conditionFieldDict: [
        {label: "药物", value: "drug"},
        {label: "药物组", value: "drugGroup"},
        {label: "制剂", value: "preparation"},
        {label: "给药途径", value: "route"},
        {label: "给药部位", value: "site"},
        {label: "剂型", value: "form"},
      ],
      /*药物分类字典 */
      drugClassifyDict: [],
    }
  },
  created() {
    this.treeList()
    //药物分类字典
     getConcept(conceptConst.DKB_DRUG_CLASSIFY, 0).then(result => {
      this.drugClassifyDict = result
    })
  },
  mounted() {
    var _this=this
    window.onresize = () => {
      setTimeout(function () {
        _this.setTableHeight()
      }, 300);
    }


  },
  methods: {
    /*设置表格的高度*/
    setTableHeight(){
      // 获取高度值 （内容高+padding+边框）
      let relevanceMsgRowHeight=document.getElementById("relevanceMsgRow").offsetHeight
      this.tableHeight = window.innerHeight - relevanceMsgRowHeight-140
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },

    /* 左侧树节点点击*/
    handleNodeClick(data, node) {
      this.nowClickNodeData=data
      this.drugCategoryId = data.id
      categoryDirInfo(data.id).then(res=>{
        this.nowClickNodeData=res.data
      })
      this.getInfo()
    },
    /*获取右侧表格数据*/
    async getInfo() {
      this.loading = true
      if (this.drugCategoryId === null || this.drugCategoryId === '' || this.drugCategoryId === undefined) {
        this.msgError('请先选择任意条药品类别！')
      } else {
        let obj = {}
        obj.categoryDirId = this.drugCategoryId
        obj.pageNum = this.pageNum
        obj.pageSize = this.pageSize
        obj.orderByColumn = this.orderByColumn || 'updateTime'
        obj.isAsc = this.isAsc || 'desc'
        let res = await info(obj)
        this.loading = false
        this.tableData = res.rows
        this.total = res.total
        this.setTableHeight()
        this.handleInfoData()
        this.tableData.forEach(row=>{
          if(row.conditionList){
            row.conditionList.forEach(condition=>{
              let fieldName = ''
              this.conditionFieldDict.some(fieldItem => {
                if (fieldItem.value == condition.field) {
                  fieldName = fieldItem.label
                  return true
                }
              })
              condition.fieldName=fieldName
            })
          }
        })
      }
    },
    /*处理关联列表的数据*/
    handleInfoData() {
      this.tableData.forEach(item => {
        if (item.drugFlagType == '1') {
          if (item.dkbSubstance && item.dkbSubstance.dkbSubstanceName) {
            //物质名称
            item.dkbSubstance.dkbSubstanceName.forEach(t => {
              if (t.isPrimary && t.isPrimary == '1') {
                item.name = t.name
                return
              }
            })
          }

          if (item.dkbSubstance && item.dkbSubstance.dkbSubstanceDesc) {
            //物质描述
            item.dkbSubstance.dkbSubstanceDesc.forEach(t => {
              if (t.isPrimary && t.isPrimary == '1') {
                item.desc = t.substanceDesc
                return
              }
            })
          }
        } else if (item.drugFlagType == '2') {
          //药物组
          if (item.dkbDrugGroup) {
            item.name = item.dkbDrugGroup.name
          }
        } else if (item.drugFlagType == '3') {
          //药物名称
          if (item.dkbDrug && item.dkbDrug.dkbDrugAliasList) {
            item.dkbDrug.dkbDrugAliasList.forEach(t => {
              if (t.isPrimary && t.isPrimary == '1') {
                item.name = t.alias
                return
              }
            })
          }
          //药物成分
          if (item.dkbDrug && item.dkbDrug.dkbDrugIngredientList) {
            item.desc = '成分:'
            item.dkbDrug.dkbDrugIngredientList.forEach(t => {
              item.desc += t.substanceName + ';'
            })
          }
        } else if (item.drugFlagType == '4') {
          //制剂名称
          if (item.ppOptBean && item.ppOptBean.preparationAliasList) {
            item.ppOptBean.preparationAliasList.forEach(t => {
              if (t.isDefault) {
                item.name = t.preparationAlias
                return
              }
            })
          }
        } else if (item.drugFlagType == '5') {
          // 制剂规格
          if (item.dkbPpSimpleBean) {
            item.name = item.dkbPpSimpleBean.preparationName
            item.desc = item.dkbPpSimpleBean.preparationSpecDesc
          }
        } else if (item.drugFlagType == '6') {
          //药品
          if (item.dkbDp && item.dkbDp.dkbDpAliasList) {
            item.dkbDp.dkbDpAliasList.forEach(aliasItem=>{
              if(conceptConst.PUB_NAME_TYPE_GENERIC==aliasItem.aliasType){
                //通用名
                item.name=aliasItem.alias
              }
            })
          }
          //药品规格
          if (item.dkbDp && item.dkbDp.dkbDpSpecDescList) {
            item.desc = ''
            item.dkbDp.dkbDpSpecDescList.forEach(t => {
              item.desc += t.specDesc + ';'
            })
          }
        } else if (item.drugFlagType == '7') {
          //包装药品
          if (item.dkbPdProd) {
            item.name = item.dkbPdProd.drugName
            item.desc = item.dkbPdProd.packSpecDesc
          }

        } else if (item.drugFlagType == '8') {
          //组合包装药品
          if (item.comboPdProd) {
            item.name = item.comboPdProd.drugName
            item.desc = item.comboPdProd.packSpecDesc
          }

        }
      })
    },
    /*获取左侧树形结构数据*/
    async treeList() {
      this.treeLoading=true
      let res = await getTreeByCategoryType(-1)
      this.treeLoading=false
      if (res.code === 200) {
        if (res.data) {
          this.treeData = res.data
          this.initTreeIdToNameDict(res.data)
          this.$nextTick(() => {
            // 触发点击事件
            this.$refs.cateGoryTree.$el.querySelector('div[role=treeitem]').click()
          })
        }
      }
    },
    //新增、编辑之后，根据categoryType刷新指定的tree
    async refreshCategoryDirTree(categoryType){
      this.treeLoading=true
      let res = await getTreeByCategoryType(categoryType)
      this.initTreeIdToNameDict(res.data)
      this.treeData.forEach(item=>{
        //console.log(item.name,item)
        if(item.categoryType==categoryType){
          item.children=[]
          item.children.push(... res.data.children)
          //console.log(item.name,item)
        }
      })
      this.treeLoading=false

    },
    // 获取新增、编辑树节点的时候的选择树结构
    getSelectTreeData(categoryType){
      //console.log("categoryType",categoryType)
      let node=this.treeData.find(item=> item.categoryType==categoryType)
      //console.log("node",node )
      if(!node){
        this.msgError("获取树形结构数据失败")
      }
      //console.log("node",node )
      this.selectTreeData=[
        {
          id:-1,
          name:"根目录",
          children:[node]
        }
      ]
      //console.log("this.selectTreeData",this.selectTreeData )
    },
    initTreeIdToNameDict(node){
      let _this=this
      let _class = Object.prototype.toString.call(node)
      if (_class === "[object Array]"){
        node.forEach(itemNode=>{
          _this.initTreeIdToNameDict(itemNode)
        })
      }else{
        this.treeIdToNameDict["node"+node.id]=node.name
        if(node.child){
          _this.initTreeIdToNameDict(node.child)
        }
      }
      //console.log("this.treeIdToNameDict",this.treeIdToNameDict)
    },
    /*左侧树节点-鼠标移入*/
    treeNodeMouseEnter(data){
      this.$set(data, 'show', true)
    },
    /*左侧树节点-鼠标移出*/
    treeNodeMouseLeave(data){
      this.$set(data, 'show', false)
    },
    /*折叠节点的全部层级*/
    foldNode(node, data){
      //console.log(node,data)
      let level=node.level
      this.treeLoading=true
      this.changeTreeNodeExpandedStatus (node,false,level)
      this.treeLoading=false

    },
    /*展开节点的全部层级*/
    expandNode(node, data){
      //console.log(node,data)
      let level=node.level
      this.treeLoading=true
      this.changeTreeNodeExpandedStatus (node,true,level)
      this.treeLoading=false

    },
    /*改变tree节点的展开折叠状态*/
    changeTreeNodeExpandedStatus (node,status,level){
      if(node.level-level>2){
        return
      }
      if(node.childNodes){
        node.childNodes.forEach(item=>{
          node.expanded=status
          if(item.childNodes){
            this.changeTreeNodeExpandedStatus (item,status,level)
          }
        })
      }

    },

    /*重置目录新增表单*/
    resetForm() {
      let form = this.$refs.editCategoryDirForm
       if (form) {
         form.resetFields()
       }
      this.form.id = undefined
      this.form.name = ''
      this.form.code = ''
      this.form.icon = ''
      this.form.pid = undefined
    },
    /*新增编辑分类-表单提交*/
    submitEditTree(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          editCategoryDir(this.form).then(response => {
            //this.treeList()
            console.log("response",response)
            if( this.form.editNode){
              //编辑，刷新树
              if(this.form.pid==-1){
                //刷新全部树
                this.treeList()
              }else{
                this.refreshCategoryDirTree( this.nowClickNodeData.categoryType)
              }

            }else{
              if(this.form.pid==-1){
                //刷新全部树
                this.treeList()
              }else{
                console.log("新增，不刷新树")
                //新增，不刷新树
                let newNode=deepClone(this.form)
                newNode.id=response.data
                newNode.allowEdit=1
                this.$refs.cateGoryTree.append(newNode,this.form.pid)
              }

            }

            this.addTreeVisible = false
            this.msgSuccess()
          })
        } else {
          this.msgError('请规范填写')
          return false
        }
      })
    },

    /*新增树形节点*/
    addNode(pNode) {

      // this.nowClickNodeData=pNode
      // this.drugCategoryId = pNode.id


      this.addTreeVisible = true
      this.selectTreeShow = false
      this.resetForm()
      if(pNode){
        this.handleNodeClick(pNode, null)
        this.$refs.cateGoryTree.setCurrentKey(pNode.id)
        this.selectTreeData=[
          {
            id:pNode.id,
            name:pNode.name,
            children:[]
          }
        ]
        this.form.pid = pNode.id
        this.form.categoryType=pNode.categoryType
      }else{
        //新增新的树
        this.selectTreeData=[
          {
            id:-1,
            name:"根目录",
            children:[]
          }
        ]
        this.form.pid = -1
        this.form.categoryType=-1
      }


      this.drugCategoryId = ''
      this.form.editNode=false
      this.$nextTick(() => {
        this.selectTreeShow = true
      })
    },
    /*编辑树形节点*/
    editNode(node, data) {
      this.handleNodeClick(data, node)
      this.$refs.cateGoryTree.setCurrentKey(data.id)
      this.drugCategoryId = data.id
      this.addTreeVisible = true
      this.resetForm()
      this.selectTreeShow = false

      this.form.id = data.id
      this.form.name = data.name
      this.form.code = data.code
      this.form.comment = data.comment
      this.form.categoryType = data.categoryType
      this.form.icon = data.icon
      this.form.pid = data.pid
      this.form.pName = this.treeIdToNameDict["node"+data.id]
      this.form.editNode=true
      this.getSelectTreeData(data.categoryType)
      this.$nextTick(() => {
        this.selectTreeShow = true
      })
    },
    /*删除树形的分类*/
    remove(node, data) {
      console.log(node,data)
      this.handleNodeClick(data, node)
      this.$refs.cateGoryTree.setCurrentKey(data.id)
      const id = data.id
      this.$confirm('确认要删除分类[' + id + ']-' + data.name + '吗?该分类下的全部子节点会一起删除。', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delCategoryDir(data.id,data.categoryType).then(res=>{
          this.msgSuccess('删除成功')
          //查询树的数据
          this.$refs.cateGoryTree.remove(node)
          // if(pid){
          //   this.refreshCategoryDirTree( this.nowClickNodeData.categoryType)
          // }else{
          //   //删除了第一级整个树
          //   this.treeList()
          // }

          //<el-tree>组件使用v-if重新加载
          // this.showDirTree = false
          // this.$nextTick(() => {
          //   this.showDirTree = true
          // })
        })
      }).then(() => {

      }).catch(function() {
      })
    },
    /*删除关联列表的数据*/
    handleDelete(item) {
      const id = item.id
      this.$confirm('是否删除名称为"' + item.name + '"的关联数据?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let obj = {}
        obj.ids = id
        dirMapDel(obj)
      }).then(() => {
        setTimeout(() => {
          this.getInfo()
        }, 100)
        this.msgSuccess('删除成功')
      }).catch(function() {
      })
    },
    /*关联列表新增*/
    addTableList() {
      //将当前点击的树节点的名字--填充到搜索条件上
      this.addRelevanceForm.searchValue= this.nowClickNodeData.name
      //this.activeBreadcrumb=1
      this.addRelevanceTableData = []
      this.addTableVisible = true
      //this.addRelevanceForm.searchValue = ''
      //this.addRelevanceForm.drugFlag = '3'
      this.$nextTick(() => {
        this.$refs.selectRelevanceDataTable.doLayout()
      })
      this.searchDataList(1)
    },
    addTableClose() {
      this.addTableVisible = false
    },
    cancleAddTable() {
      this.addTableVisible = false
    },
    /*新增关联列表--表单提交*/
    async submitAddTable() {
      let obj = {}
      obj.categoryDirId = this.drugCategoryId
      obj.data = []
      this.tableChoiceData.forEach((item) => {
        let Object = {}
        Object.categoryDirId = this.drugCategoryId
        Object.drugFlagCode = item.id
        Object.drugFlagType = this.addRelevanceForm.drugFlag
        obj.data.push(Object)
      })
      let res = await dirMap(obj)
      if (res.code === 200) {
        this.msgSuccess('新增成功')
        this.getInfo()
        this.addTableVisible = false
      }

    },
    /*获取关联列表选中的数据*/
    handleSelectionChange(val) {
      this.tableChoiceData = val
    },
    // 选择图标
    selectedIcon(name) {
      this.form.icon = name
    },
    /** 转换菜单数据结构 */
    normalizer(node) {
      if (!(node.children && node.children.length > 0)) {
        delete node.children
      }
       let isDisabled = false
      if (this.drugCategoryId && this.drugCategoryId == node.id) {
        isDisabled = true
      }

      return {
        id: node.id,
        label: node.name,
        isDisabled: isDisabled
      }
    },
    /* 分类名称-重复性校验*/
    validateCategoryName(rule, value, callback, index) {
      if (!isEmpty(value)) {
        // 调用接口校验
        checkFields( this.nowClickNodeData.categoryType,'name', value.trim(), this.form.id).then(response => {
          if (response.data && response.data.id) {
            callback(new Error('分类名称已存在,id为[' + response.data.id + ']'))
          } else {
            callback()
          }
        })
      } else {
        callback()
      }
    },
    /* 分类代码-重复性校验*/
    validateCategoryCode(rule, value, callback, index) {
      if (!isEmpty(value)) {
        // 调用接口校验
        checkFields(this.nowClickNodeData.categoryType,'code', value.trim(), this.form.id).then(response => {
          if (response.data && response.data.id) {
            callback(new Error('分类编码已存在,分类名称为[' + response.data.name + ']'))
          } else {
            callback()
          }
        })
      } else {
        callback()
      }
    },
    searchDataList(type) {
      if(type==1){
        this.addRelevanceForm.includeDrugCategory=1
        this.addRelevanceForm.categoryDirId=this.drugCategoryId
        this.addRelevanceForm.pageNum=1
        this.addRelevanceForm.pageSize=20
        this.addRelevanceTableDataTotal=0
      }
      let drugFlag = this.addRelevanceForm.drugFlag
      this.loading = true
      if (drugFlag == '1') {
        //查询物质列表
        this.selectSubstanceList()
      } else if (drugFlag == '2') {
        //查询药物组列表
        this.selectDrugGroupList()
      } else if (drugFlag == '3') {
        //查询药物
        this.selectDrugList()
      } else if (drugFlag == '4') {
        //查询制剂
        this.selectPreparationList()
      } else if (drugFlag == '5') {
        //制剂规格
        this.selectPreparationSpecList()
      } else if (drugFlag == '6') {
        //药品
        this.selectDrugProdList()
      } else if (drugFlag == '7') {
        //包装药品
        this.selectPkgDrugProdList()
      } else if (drugFlag == '8') {
        //组合包装药品
        this.selectComboDrugList()
      }

    },
    /*查询物质列表*/
    selectSubstanceList() {
      substanceList(this.addRelevanceForm).then(response => {
        this.loading = false
        this.addRelevanceTableData = response.rows
        this.addRelevanceTableDataTotal=response.total
        this.addRelevanceTableData.forEach((item) => {

          //物质名称
          if (item.dkbSubstanceName) {
            item.dkbSubstanceName.forEach((aliasItem) => {
              if (aliasItem.isPrimary && aliasItem.isPrimary == '1') {
                item.substanceName = aliasItem.name
                return
              }
            })
          }

          // 物质描述
          if (item.dkbSubstanceDesc) {
            item.dkbSubstanceDesc.forEach((t) => {
              if (t.isPrimary && t.isPrimary == '1') {
                item.substanceDesc = t.substanceDesc
                return
              }
            })
          }
        })

      })
    },
    /*查询药物组列表*/
    selectDrugGroupList() {
      drugGroupList(this.addRelevanceForm).then(response => {
        this.loading = false
        this.addRelevanceTableDataTotal=response.total
        this.addRelevanceTableData = response.rows
      })
    },
    /*查询药物列表*/
    selectDrugList() {
      drugList(this.addRelevanceForm).then(response => {
        this.loading = false
        this.addRelevanceTableDataTotal=response.total
        this.addRelevanceTableData = response.rows
        this.addRelevanceTableData.forEach((item) => {
          //药物名称
          if (item.dkbDrugAliasList) {
            item.dkbDrugAliasList.forEach((aliasItem) => {
              if (aliasItem.isPrimary && aliasItem.isPrimary == '1') {
                item.drugName = aliasItem.alias
                return
              }
            })
          }

          //药物成分
          if (item.dkbDrugIngredientList) {
            item.ingredient = ''
            item.dkbDrugIngredientList.forEach((t) => {
              item.ingredient += t.substanceName + ';'
            })
          }
        })
      })
    },
    /*查询制剂列表*/
    selectPreparationList() {
      this.addRelevanceForm.name = this.addRelevanceForm.searchValue
      preparationList(this.addRelevanceForm).then(response => {
        this.loading = false
        this.addRelevanceTableDataTotal=response.data.total
        this.addRelevanceTableData = response.data.rows
        this.addRelevanceTableData.forEach((item) => {
          item.id = item.preparationId
        })
      })
    },
    /*查询制剂规格列表*/
    selectPreparationSpecList() {
      this.addRelevanceForm.name = this.addRelevanceForm.searchValue
      preparationSpecList(this.addRelevanceForm).then(response => {
        this.loading = false
        this.addRelevanceTableDataTotal=response.total
        this.addRelevanceTableData = response.rows
        this.addRelevanceTableData.forEach((item) => {
          item.id = item.preparationSpecId
        })
      })
    },
    /*查询药品列表*/
    selectDrugProdList() {
      this.addRelevanceForm.name = this.addRelevanceForm.searchValue
      drugProdList(this.addRelevanceForm).then(response => {
        this.loading = false
        this.addRelevanceTableDataTotal=response.total
        this.addRelevanceTableData = response.rows
        this.addRelevanceTableData.forEach((item) => {
          //批准文号
          item.approvalNum = ''
          if (item.dkbDpApprovalNumList) {
            item.dkbDpApprovalNumList.forEach((approvalNumItem) => {
              if (approvalNumItem.approvalNum) {
                item.approvalNum += approvalNumItem.approvalNum + ';'
              }
            })
            item.approvalNum=item.approvalNum.substr(0,item.approvalNum.length-1)
          }
          //名称
          if(item.dkbDpAliasList){
            item.dkbDpAliasList.forEach(aliasItem=>{
              if(conceptConst.PUB_NAME_TYPE_GENERIC==aliasItem.aliasType){
                //通用名
                item.genericName=aliasItem.alias
              }else if(conceptConst.PUB_NAME_TYPE_TRADE==aliasItem.aliasType){
                //商品名
                item.tradeName=aliasItem.alias
              }

            })
          }

          //规格描述
          item.specDesc = ''
          if (item.dkbDpSpecDescList) {
            item.dkbDpSpecDescList.forEach((specDescItem) => {
              if (specDescItem.specDesc) {
                item.specDesc += specDescItem.specDesc + ';'
              }
            })
            item.specDesc=item.specDesc.substr(0,item.specDesc.length-1)
          }

          //生产企业
          item.manufacturerName=''
          if (item.dkbDpManufacturerList) {
            item.dkbDpManufacturerList.forEach((manufacturerItem) => {
              if (manufacturerItem.manufacturerName) {
                item.manufacturerName += manufacturerItem.manufacturerName+";"
              }
            })
            item.manufacturerName=item.manufacturerName.substr(0,item.manufacturerName.length-1)
          }

          //本位码
          item.standardCode=''
          if (item.dkbDpStandardCodeList) {
            item.dkbDpStandardCodeList.forEach((standardCodeItem) => {
              if (standardCodeItem.standardCode) {
                item.standardCode += standardCodeItem.standardCode+";"
              }
            })
            item.standardCode=item.standardCode.substr(0,item.standardCode.length-1)
          }

        })
      })
    },
    /*查询包装药品列表*/
    selectPkgDrugProdList() {
      pdkDrugProdList(this.addRelevanceForm).then(response => {
        this.loading = false
        this.addRelevanceTableDataTotal=response.total
        this.addRelevanceTableData = response.rows
        this.addRelevanceTableData.forEach((item) => {
          item.pkgDrugProdName=item.drugName
        })
      })
    },
    /*查询组合包装药品列表*/
    selectComboDrugList() {
      comboDrugList(this.addRelevanceForm).then(response => {
        this.loading = false
        this.addRelevanceTableDataTotal=response.total
        this.addRelevanceTableData = response.rows
        this.addRelevanceTableData.forEach((item) => {
          item.comboDrugProdName=item.drugName
        })
      })
    },
    // 药物分类下拉列表变化
    drugClassifySelectChange(){

    },
    /*数据类型下拉列表变化*/
    typeSelectChange() {
      this.addRelevanceForm.drugClassify=undefined
      this.breadcrumbSearchParams={
        drugId:undefined,
        ppId:undefined,
        specId:undefined,
      }
      let drugFlag=this.addRelevanceForm.drugFlag
      if(drugFlag==2 || drugFlag==1){
        this.activeBreadcrumb=0
      }else  if(drugFlag==3){
        this.activeBreadcrumb=1
      }else  if(drugFlag==4){
        this.activeBreadcrumb=2
      }else  if(drugFlag==5){
        this.activeBreadcrumb=3
      }else  if(drugFlag==6){
        this.activeBreadcrumb=4
      }else  if(drugFlag==7){
        this.activeBreadcrumb=5
      }else  if(drugFlag==8){
        this.activeBreadcrumb=0
      }
      this.addRelevanceTableData = []
      this.$nextTick(() => {
        this.$refs.selectRelevanceDataTable.doLayout()
      })
      this.searchDataList(1)
    },
    /*面包屑导航点击*/
    breadcrumbItemClick(type){
      if(type==1){
        //查询药物列表
        this.activeBreadcrumb=1
        this.addRelevanceForm.drugFlag= '3'
        this.addRelevanceForm.drugId=undefined
        this.addRelevanceForm.ppId=undefined
        this.addRelevanceForm.specId=undefined
        this.searchDataList(1)
      }else if(type==2){
        //查询制剂列表
        this.activeBreadcrumb=2
        this.addRelevanceForm.drugFlag= '4'
        this.addRelevanceForm.drugId=this.breadcrumbSearchParams.drugId
        this.addRelevanceForm.ppId=undefined
        this.addRelevanceForm.specId=undefined
        //this.breadcrumbDesc.preparationAlias=""
        this.searchDataList(1)
      }else if(type==3){
        //查询制剂规格列表
        this.activeBreadcrumb=3
        this.addRelevanceForm.drugFlag= '5'
        this.addRelevanceForm.drugId=undefined
        this.addRelevanceForm.ppId=this.breadcrumbSearchParams.ppId
        this.addRelevanceForm.specId=undefined
        this.searchDataList(1)
      }
    },
    clickDrugRowSearch(row,type){
      this.addRelevanceForm.searchValue=""
      if(type==1){
        //表格-点击药物名称查询制剂
        this.activeBreadcrumb=2
        //this.breadcrumbDesc.drugName=row.drugName
        this.breadcrumbSearchParams.drugId=row.id
        this.addRelevanceForm.drugId=row.id
        this.addRelevanceForm.drugFlag= '4'
        this.searchDataList(1)
      }else if(type==2){
        // 表格-点击制剂名称查询制剂规格
        this.activeBreadcrumb=3
        this.breadcrumbSearchParams.ppId=row.preparationId
        this.addRelevanceForm.ppId=row.preparationId
        this.addRelevanceForm.drugId=undefined
        this.addRelevanceForm.drugFlag= '5'
        this.searchDataList(1)
      }else if(type==3){
        // 表格-点击制剂规格查询药品
        this.activeBreadcrumb=4
        this.breadcrumbSearchParams.specId=row.preparationSpecId
        this.addRelevanceForm.specId=row.preparationSpecId
        this.addRelevanceForm.ppId=undefined
        this.addRelevanceForm.drugId=undefined
        this.addRelevanceForm.drugFlag= '6'
        this.searchDataList(1)
      }

    },
    /*判断新增关联列表--表格行的多选框是否可以选择*/
    rowCanChecked(row, index){
      if(row.drugCategoryChecked==true){
        return false
      }else{
        return true
      }

    },
    //保存条件规则后触发
    saveCondition(){
      //刷新关联信息列表
      this.getInfo()
    },
    //数据同步，根据规则重新同步数据
    refreshDirRefData(){
      refreshDirRefData(this.drugCategoryId).then(res=>{
        this.getInfo().then(r=>{
          this.msgSuccess()
        })
      })
    }

  }
}
</script>

<style scoped>
.el-aside {
  background-color: #fff;
  color: #333;
  text-align: center;
  /*height: 1000px;*/
  border-right: 1px solid #ccc;
}

.el-main {
  background-color: #fff;
  color: #333;
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  text-align: left;
  width: 100%;
}

.tree-node-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  width: calc(100% - 120px);
}

.el-breadcrumb__inner div{
  display: inline-block;
}

.el-breadcrumb__inner div:hover{
  color: #409eff;
  cursor:pointer
}

.el-breadcrumb__inner .breadcrumb-item-name{
  font-weight: 700;
  text-align: center;
  line-height: 26px;
}



.el-breadcrumb__inner .breadcrumb-item-desc{
  line-height: 26px;
}



</style>


<style>
#addRelevanceDialog .el-dialog__body{
  padding: 10px 20px 6px 20px;
}

/*调整新增关联表格的多选框的大小*/
.relevance-table .el-checkbox__inner{
  width:25px;
  height: 25px;
}
/*调整新增关联表格的多选框的大小*/
.relevance-table .el-checkbox__inner::after{
  height: 12px;
  left:10px;
  top: 4px;
}

</style>
